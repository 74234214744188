<template>
  <div>
    <a-card title="产品开发与记录">
      <a-row>
        <a-col :span="6" :md="8" :xl="6" style="max-width: 256px">
          <a-input-search v-model="searchForm.search" placeholder="产品编号/名称" allowClear @search="search" />
        </a-col>

        <a-col :span="6" :md="8" :xl="6" style="margin-left: 10px;max-width: 256px">
          <a-select v-model="searchForm.status" placeholder="选择状态" allowClear style="width: 100%;"
            @change="changeCategory">
            <a-select-option v-for="key,value in statusItems" :key="key" :value="value">{{ key }}
            </a-select-option>
          </a-select>
        </a-col>

      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading"
          :pagination="pagination" @change="tableChange">
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">开发</a-button>
              <a-button size="small" type="primary" @click="set_goods_price(item)">定价</a-button>
            </a-button-group>
          </div>

        </a-table>
      </a-row>
    </a-card>
  </div>


</template>

<script>
import { developGoodsList } from '@/api/goods'

export default {
  name: 'Develop',
  data() {
    return {
      statusItems: 
        { concept: '待开发',in_development: '开发中',launched: '已发布',pending_review: '待审核',},
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (value, item, index) => {
            return index + 1
          },
          width: 45
        },
        {
          title: '创建人',
          dataIndex: 'bm_user_name',
        },
        {
          title: 'cs_id',
          dataIndex: 'cs_id',
        },
        {
          title: 'bm 产品名称',
          dataIndex: 'bm_goods_name',
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (value, item) => {
            return this.statusItems[item.status]
          },
        },
        {
          title: '下单备注',
          dataIndex: 'remark',
        },
        {
          title: '结果',
          dataIndex: 'bm_remark',
        },
        {
          title: '创建日期',
          dataIndex: 'create_time',
          width: 150
        },
        {
          title: '更新日期',
          dataIndex: 'update_time',
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 147
        },
      ],
      searchForm: { page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      form: {},
    };
  },
  computed: {
  },
  methods: {
    initialize() {
      this.list();
    },

    list() {
      this.loading = true;
      developGoodsList(this.searchForm).then(data => {
        this.pagination.total = data.count;
        this.items = data.results;
      }).finally(() => {
        this.loading = false;
      });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
      this.list();
    },

    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    detial(item) {
      this.$router.push({ path: '/goods/dev_record_detail', query: { id: item.id } });
    },

    set_goods_price(item){
      if(item.goods == null){
        this.$message.error('请先匹配BM产品');
        return;
      }
      this.$router.push({ path: '/goods/price', query: { goods: item.goods } });
    
    },

    changeCategory(){
      this.list();
    }
  },
  mounted() {
    this.initialize();
  },
}
</script>